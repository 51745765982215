.project-insights-container {
    width: 100%;

    

    .items-container {
        width: 100%;

        &.item-i-cb{
            width: auto;
            margin-left: -12px;
            margin-right: -12px;
        }

        .card {
            background: var(--white);
            border-radius: 10px;
            padding: calc(8px + 1vw) calc(10px + 0.5vw);

            p {
                font-weight: 600;
                font-size: calc(30px + 1vw);
                line-height: 52px;
                text-transform: capitalize;
                white-space: nowrap;
            }

            span {
                font-weight: 500;
                font-size: 15px;
                line-height: 21px;
                text-transform: capitalize;
                opacity: 0.6;
            }
        }
    }
}