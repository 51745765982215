.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
  margin: 0;

  .logo-container {
    position: relative;
    top: 50px;
  }
  .container-step-1 {
    position: relative;
    top: 90px;
    width: min(500px, 100%);
    min-height: 80vh;
    padding: 0 20px;
    .info {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
    .input-container {
      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        letter-spacing: -0.01em;
        font-feature-settings: "calt" off;
        margin-bottom: 5px;
      }
      input {
        border: 1px solid #cbd2e0;
        border-radius: 8px;
      }
      input[type="password"] {
        border: 1px solid #cbd2e0;
        border-radius: 8px;
        font-family: Verdana;
      }
    }
    .signup-btn {
      color: #fff;
      font-weight: 700;
      font-size: 17px;
      line-height: 150%;
      height: 45px;
      letter-spacing: 0.05em;
    }
    .bold {
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;

      text-align: center;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;
    }
  }
}
.step {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
}
.extra-content {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 20px */
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  font-feature-settings: "calt" off;

  color: #1976d2;
}
