.handle-more {
    background: none;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    margin-top: 0px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
   
  }
  .disabledBtn{
    opacity: 0.5;
    pointer-events: none;
  }

  .hideInputcontainer{
    display: none;
  }
